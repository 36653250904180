import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import axiosInstance from "../../../service/axiosInstance";
import { useStateValue } from "../../../context/myContext";
import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters long")
    .max(16, "Name must be at most 16 characters long")
    .required("Required"),
  surname: Yup.string()
    .min(2, "Surname must be at least 2 characters long")
    .max(16, "Surname must be at most 16 characters long")
    .required("Required"),
  birthDay: Yup.date().required("Required"),
  birthPlace: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .min(12, "Minimum 12 character (XXX-XXX-XXXX)")
    .required("Required")
    .matches(
      /\d{3}-\d{3}-\d{4}/g,
      "Phone number must be in format XXX-XXX-XXXX"
    ),
  ssn: Yup.string()
    .min(11, "Minimum 11 character (XXX-XX-XXXX)")
    .required("Required")
    .matches(/\d{3}-\d{2}-\d{4}/g, "SSN must be in format XXX-XX-XXXX"),
  username: Yup.string()
    .min(4, "At least 4 char")
    .max(25, "At least 25 char")
    .required("Required"),
  password: Yup.string()
    .min(8, "Minimum 8 character")
    .max(60, "Password must be at most 60 characters long")
    .required("Required"),
  gender: Yup.string()
    .required("Please select your gender")
    .oneOf(["MALE", "FEMALE"], "You must select a gender"),
});

const DeanManagement = () => {
  //Context
  const { setMyState } = useStateValue();

  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  //Offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Dean Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const searchSort = "id";
  const searchtype = "desc";
  const [deanPage, setDeanPage] = useState([]);
  const [deanPage2, setDeanPage2] = useState([]);

  const getDeanPage = () => {
    axiosInstance
      .get(
        "/dean/search" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage +
          "&sort=" +
          searchSort +
          "&type=" +
          searchtype
      )
      .then((response) => {
        setDeanPage(response.data);
        setDeanPage2(response.data.content);
      });
  };

  useEffect(() => {
    if (data !== null) getDeanPage();
  }, [currentPage]);

  const deanList = deanPage2;

  const totalPages = deanPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (currentPage < Math.ceil(deanPage.totalElements / rowsPerPage)) {
      setCurrentPage(Math.ceil(deanPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (currentPage < Math.ceil(deanPage.totalElements / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Get All Deans
  /* const [deans, setDeans] = useState([]);

  const getDean = () => {
    axiosInstance.get("/dean/getAll").then((response) => {
      setDeans(response.data);
    });
  };

  useEffect(() => {
    getDean();
  }, []);

  const list = deans; */

  //Edit Dean
  const [editDeans, setEditDeans] = useState({});

  const getDeanForEdit = async (id) => {
    try {
      const response = await axiosInstance
        .get("/dean/getManagerById/" + id)
        .then((response) => {
          setEditDeans(response.data.object);
        });
      handleShow();
    } catch (error) {}
  };

  const name = editDeans.name;
  const birthDay = editDeans.birthDay;
  const birthPlace = editDeans.birthPlace;
  const surname = editDeans.surname;
  const phoneNumber = editDeans.phoneNumber;
  const ssn = editDeans.ssn;
  const username = editDeans.username;
  const userId = editDeans.userId;

  //Delete Dean
  const handleDelete = (id) => {
    axiosInstance.delete("/dean/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
        getDeanPage();
      } else {
        toast.success("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data.util !== 6982 && <Navigate to="/login" />}

      {data && data.util === 6982 && (
        <div>
          <Container fluid>
            <Card
              className="mt-4 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                Dean Management
              </Card.Header>
            </Card>
          </Container>
          {/* Add Dean Start*/}
          <Container fluid>
            <Formik
              initialValues={{
                name: "",
                surname: "",
                gender: "",
                birthDay: "",
                birthPlace: "",
                phoneNumber: "",
                ssn: "",
                username: "",
                password: "",
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .post("/dean/save", values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      setMyState({
                        type: "DEAN",
                        item: userInfo.object,
                      });
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      actions.resetForm();
                      getDeanPage();
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.name, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      toast.error(error.response.data.validations.surname, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.gender, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.birthPlace, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.username, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.phoneNumber, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.ssn, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.birthDay, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.password, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                    }
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <Card
                  className="mt-5 ms-3 me-3 mb-5 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                  onSubmit={formikk.handleSubmit}
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                    Add Dean
                  </Card.Header>
                  <Card.Body>
                    <>
                      <Form>
                        <Row>
                          <Col md="auto">
                            <FloatingLabel
                              controlId="name"
                              label="Name"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Name"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.name}
                                isInvalid={!!formikk.errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.name}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col md="auto">
                            <FloatingLabel
                              controlId="surname"
                              label="Surname"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Surname"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.surname}
                                isInvalid={!!formikk.errors.surname}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.surname}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col md="auto">
                            <FloatingLabel
                              controlId="birthPlace"
                              label="Birth Place"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Birth Place"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.birthPlace}
                                isInvalid={!!formikk.errors.birthPlace}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.birthPlace}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col md="auto" className="text-center ">
                            <Form.Label>Gender</Form.Label>
                            <Row>
                              <Col>
                                <Form.Check
                                  inline
                                  label="Female"
                                  name="gender"
                                  type="radio"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value="FEMALE"
                                />
                              </Col>
                              <Col>
                                <Form.Check
                                  inline
                                  label="Male"
                                  name="gender"
                                  type="radio"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value="MALE"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="birthDay">
                              <Form.Label>Date Of Birth</Form.Label>

                              <Form.Control
                                as="input"
                                type="date"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.birthDay}
                                isInvalid={!!formikk.errors.birthDay}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.birthDay}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col md="auto">
                            <FloatingLabel
                              controlId="phoneNumber"
                              label="Phone (XXX-XXX-XXXX)"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Phone Number"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.phoneNumber}
                                isInvalid={!!formikk.errors.phoneNumber}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.phoneNumber}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col md="auto">
                            <FloatingLabel
                              controlId="ssn"
                              label="Ssn (XXX-XX-XXXX)"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="ssn"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.ssn}
                                isInvalid={!!formikk.errors.ssn}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.ssn}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col md="auto">
                            <FloatingLabel
                              controlId="username"
                              label="User Name"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="username"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.username}
                                isInvalid={!!formikk.errors.username}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.username}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col md="auto">
                            <FloatingLabel
                              controlId="password"
                              label="Password"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="password"
                                placeholder="Password"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.password}
                                isInvalid={!!formikk.errors.password}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.password}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <div className="mx-auto p-3">
                              <Button
                                size="lg"
                                variant="primary"
                                className="fw-semibold"
                                onClick={() => {
                                  formikk.submitForm();
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  </Card.Body>
                </Card>
              )}
            </Formik>
            <ToastContainer />
          </Container>
          {/* Add Dean End*/}

          {/* GetAll Dean Start*/}
          <Container fluid>
            <Card
              className="mb-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                Dean List
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Gender</th>
                      <th>Phone Number</th>
                      <th>Ssn</th>
                      <th>User Name</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {deanList.map((item) => (
                      <tr key={item.userId}>
                        <td>
                          <span>
                            {item.name} {item.surname}
                          </span>
                        </td>

                        <td>
                          <span>{item.gender}</span>
                        </td>

                        <td>
                          <span>{item.phoneNumber}</span>
                        </td>

                        <td>
                          <span>{item.ssn}</span>
                        </td>
                        <td>
                          <span>{item.username}</span>
                        </td>
                        <td>
                          <span>
                            <Button
                              variant="outline-info"
                              className="text-dark"
                              onClick={() => {
                                getDeanForEdit(item.userId);
                              }}
                            >
                              <i className="fa-solid fa-pencil" />
                              &nbsp; Edit
                            </Button>
                          </span>
                        </td>
                        {/* <td>
                          <span>
                            <Button
                              variant="danger"
                              onClick={() => handleDelete(item.userId)}
                            >
                              <i className="fa-solid fa-trash" />
                            </Button>
                          </span>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col className="d-flex justify-content-start ps-5">
                    Page {currentPage} of {totalPages}
                  </Col>
                  <Col>
                    <Pagination className="d-flex justify-content-end pe-3">
                      <Pagination.First onClick={showFirstPage} />
                      <Pagination.Prev onClick={showPrevPage} />

                      <Pagination.Item active>{currentPage}</Pagination.Item>

                      <Pagination.Next onClick={showNextPage} />
                      <Pagination.Last onClick={showLastPage} />
                    </Pagination>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
          {/* GetAll Dean End*/}

          {/* Edit Dean Start*/}
          <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Edit Dean</Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{
                name: name,
                surname: surname,
                gender: "",
                birthDay: birthDay,
                birthPlace: birthPlace,
                phoneNumber: phoneNumber,
                ssn: ssn,
                username: username,
                password: "",
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .put("/dean/update/" + userId, values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      setMyState({
                        type: "DEANEDIT",
                        item: userInfo.object,
                      });
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      getDeanPage();
                      handleClose()
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.name, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      toast.error(error.response.data.validations.surname, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.gender, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.birthPlace, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.username, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.phoneNumber, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.ssn, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.birthDay, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.password, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                    }
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <div>
                  <Modal.Body>
                    <Card
                      className="text-center border border-3"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Card.Body>
                        <>
                          <Form>
                            <Row>
                              <Col md="auto">
                                <FloatingLabel
                                  controlId="name"
                                  label="Name"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Name"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.name}
                                    isInvalid={!!formikk.errors.name}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.name}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto">
                                <FloatingLabel
                                  controlId="surname"
                                  label="Surname"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Surname"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.surname}
                                    isInvalid={!!formikk.errors.surname}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.surname}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto">
                                <FloatingLabel
                                  controlId="birthPlace"
                                  label="Birth Place"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Birth Place"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.birthPlace}
                                    isInvalid={!!formikk.errors.birthPlace}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.birthPlace}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto" className="text-center ">
                                <Form.Label>Gender</Form.Label>
                                <Row>
                                  <Col>
                                    <Form.Check
                                      inline
                                      label="Female"
                                      name="gender"
                                      type="radio"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value="FEMALE"
                                    />
                                  </Col>
                                  <Col>
                                    <Form.Check
                                      inline
                                      label="Male"
                                      name="gender"
                                      type="radio"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value="MALE"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Form.Group
                                  className="mb-3"
                                  controlId="birthDay"
                                >
                                  <Form.Label>Date Of Birth</Form.Label>

                                  <Form.Control
                                    as="input"
                                    type="date"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.birthDay}
                                    isInvalid={!!formikk.errors.birthDay}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.birthDay}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col md="auto">
                                <FloatingLabel
                                  controlId="phoneNumber"
                                  label="Phone (XXX-XXX-XXXX)"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Phone Number"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.phoneNumber}
                                    isInvalid={!!formikk.errors.phoneNumber}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.phoneNumber}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto">
                                <FloatingLabel
                                  controlId="ssn"
                                  label="Ssn (XXX-XX-XXXX)"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="ssn"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.ssn}
                                    isInvalid={!!formikk.errors.ssn}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.ssn}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto">
                                <FloatingLabel
                                  controlId="username"
                                  label="User Name"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="username"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.username}
                                    isInvalid={!!formikk.errors.username}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.username}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto">
                                <FloatingLabel
                                  controlId="password"
                                  label="Password"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="password"
                                    placeholder="Password"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.password}
                                    isInvalid={!!formikk.errors.password}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.password}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      size="lg"
                      variant="primary"
                      className="fw-semibold"
                      onClick={() => {
                        formikk.submitForm();
                      }}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Modal>
          {/* Edit Dean End*/}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default DeanManagement;
