import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/common/Home";
import Page404 from "../pages/auth/Page404";
import Login from "../pages/auth/Login";
import AdminManagement from "../pages/admin/AdminManagement";
import DeanManagement from "../pages/admin/dean/DeanManagement";
import Logout from "../pages/auth/Logout";
import ViceDeanManagement from "../pages/admin/viceDean/ViceDeanManagement";
import LessonPage from "../pages/vicedean/lesson/LessonPage";
import TeacherPage from "../pages/vicedean/TeacherPage";
import StudentsPage from "../pages/vicedean/StudentsPage";
import ChooseLessonPage from "../pages/student/ChooseLessonPage";
import GradesPage from "../pages/student/GradesPage";
import StudentInfoPage from "../pages/teacher/StudentInfoPage";
import MeetPage from "../pages/teacher/MeetPage";
import Contact from "../pages/common/Contact";
import ContactGetAll from "../pages/contactGet/ContactGetAll";
import Register from "../pages/auth/Register";
import GuestUser from "../pages/guestUser/GuestUser";
import Courses from "../pages/common/Courses";
import Events from "../pages/common/Events";
import Blog from "../pages/common/Blog";

const _loading = (
  <div className="spinner-grow" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
);

const Content = () => {
  return (
    <main className="content">
      <Suspense fallback={_loading}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/events" element={<Events />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact-getAll" element={<ContactGetAll />} />
          <Route path="/guest-user" element={<GuestUser />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/admin-management" element={<AdminManagement />} />
          <Route path="/dean-management" element={<DeanManagement />} />
          <Route path="/vicedean-management" element={<ViceDeanManagement />} />
          <Route path="/lesson" element={<LessonPage />} />
          <Route path="/teacher" element={<TeacherPage />} />
          <Route path="/student" element={<StudentsPage />} />
          <Route path="/studentInfo" element={<StudentInfoPage />} />
          <Route path="/meet" element={<MeetPage />} />
          <Route path="/chooseLesson" element={<ChooseLessonPage />} />
          <Route path="/grades-announcements" element={<GradesPage />} />
        </Routes>
      </Suspense>
    </main>
  );
};

export default Content;
