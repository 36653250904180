import React from "react";
import blog01 from "../../assets/images/blog/blog_01.jpg";
import blog02 from "../../assets/images/blog/blog_02.jpg";
import blog03 from "../../assets/images/blog/blog_03.jpg";
import blog07 from "../../assets/images/blog/blog_07.jpg";
import blog08 from "../../assets/images/blog/blog_08.jpg";
import blog09 from "../../assets/images/blog/blog_09.jpg";

const Blog = () => {
  return (
    <div>
      <section className="page-header">
        <div className="overlay">
          <div className="container">
            <h3>Blog Grid</h3>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li> - </li>
              <li>Blog</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog padding-120">
        <div className="container">
          <div className="blog-items">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog01}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">Actanualze Cententrc Staled</a>
                    </h4>
                    <p>
                      Comptely actuaze cent centric coloratons an shang without
                      ainstalled and awesome kidschool PSD Template.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href="#">
                        <span className="icon fa-solid fa-calendar-days"></span>
                        22.04.2017
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-heart"></span>24
                        Like
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-message"></span>
                        24 Comment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog02}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">Actanualze Cententrc Staled</a>
                    </h4>
                    <p>
                      Comptely actuaze cent centric coloratons an shang without
                      ainstalled and awesome kidschool PSD Template.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href="#">
                        <span className="icon fa-solid fa-calendar-days"></span>
                        22.04.2017
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-heart"></span>24
                        Like
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-message"></span>24
                        Comment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog03}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">Actanualze Cententrc Staled</a>
                    </h4>
                    <p>
                      Comptely actuaze cent centric coloratons an shang without
                      ainstalled and awesome kidschool PSD Template.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href="#">
                        <span className="icon fa-solid fa-calendar-days"></span>
                        22.04.2017
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-heart"></span>24
                        Like
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-message"></span>24
                        Comment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog07}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">Actanualze Cententrc Staled</a>
                    </h4>
                    <p>
                      Comptely actuaze cent centric coloratons an shang without
                      ainstalled and awesome kidschool PSD Template.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href="#">
                        <span className="icon fa-solid fa-calendar-days"></span>
                        22.04.2017
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-heart"></span>24
                        Like
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-message"></span>24
                        Comment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog08}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">Actanualze Cententrc Staled</a>
                    </h4>
                    <p>
                      Comptely actuaze cent centric coloratons an shang without
                      ainstalled and awesome kidschool PSD Template.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href="#">
                        <span className="icon fa-solid fa-calendar-days"></span>
                        22.04.2017
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-heart"></span>24
                        Like
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-message"></span>24
                        Comment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog09}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">Actanualze Cententrc Staled</a>
                    </h4>
                    <p>
                      Comptely actuaze cent centric coloratons an shang without
                      ainstalled and awesome kidschool PSD Template.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href="#">
                        <span className="icon fa-solid fa-calendar-days"></span>
                        22.04.2017
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-heart"></span>24
                        Like
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa-regular fa-message"></span>24
                        Comment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
