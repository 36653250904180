import React, { useEffect } from "react";
import Content from "./layout/Content";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import axiosInstance from "./service/axiosInstance";
import { useStateValue } from "./context/myContext";

function App() {
  const { setMyData } = useStateValue();

  const loadUser = async () => {
    try {
      const response = await axiosInstance.get("/auth/me")
      const userInfo= response?.data?.object

      setMyData({
        type: "LOGIN",
        item: userInfo,
      });      
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=> {
    loadUser();
  },[])

  return (
    <div className="app">
      
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
