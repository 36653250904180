import React, { useCallback } from "react";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as Yup from "yup";
import logo from "../../assets/images/logo.png";
import { useStateValue } from "../../context/myContext";
import axiosInstance from "../../service/axiosInstance";

const schema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().min(8, "Minimum 8 character").required("Required"),
});

const Login = () => {
  const { setMyData } = useStateValue();

  const navigate = useNavigate();
  const adminManagement = useCallback(
    () => navigate("/admin-management"),
    [navigate]
  );
  const viceDeanManagement = useCallback(
    () => navigate("/vicedean-management"),
    [navigate]
  );
  const lesson = useCallback(() => navigate("/lesson"), [navigate]);
  const chooseLesson = useCallback(() => navigate("/chooseLesson"), [navigate]);
  const studentInfo = useCallback(() => navigate("/studentInfo"), [navigate]);
  const login = useCallback(() => navigate("/login"), [navigate]);

  return (
    <div className="page justify-content-center align-items-center">
      <Card
        className="text-center justify-content-center align-items-center shadow-lg p-3 bg-body rounded"
        style={{ width: "30rem" }}
        border="warning"
      >
        <Link to="/">
          <Card.Img
            variant="top"
            style={{ width: "10rem", margin: "-1.3rem 0rem -0.9rem 0rem" }}
            src={logo}
            className="p-4 pb-6"
            alt="logo"
          />
        </Link>

        <Card.Body>
          <Card.Title className="mb-4 fw-semibold shadow-sm bg-body-tertiary">
            Login
          </Card.Title>
          <>
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .post("/auth/login", values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      localStorage.setItem(
                        "n",
                        JSON.stringify({
                          n: userInfo.name,
                        })
                      );
                      localStorage.setItem(
                        "auth",
                        JSON.stringify({
                          token: userInfo.token,
                        })
                      );
                      setMyData({
                        type: "LOGIN",
                        item: userInfo,
                      });

                      if (userInfo.role === "ADMIN") {
                        adminManagement();
                        localStorage.setItem(
                          "util",
                          JSON.stringify({
                            util: 6982,
                          })
                        );
                      } else if (userInfo.role === "MANAGER") {
                        viceDeanManagement();
                        localStorage.setItem(
                          "util",
                          JSON.stringify({
                            util: 4984,
                          })
                        );
                      } else if (userInfo.role === "ASSISTANTMANAGER") {
                        lesson();
                        localStorage.setItem(
                          "util",
                          JSON.stringify({
                            util: 5487,
                          })
                        );
                      } else if (userInfo.role === "TEACHER") {
                        studentInfo();
                        localStorage.setItem(
                          "util",
                          JSON.stringify({
                            util: 2463,
                          })
                        );
                      } else if (userInfo.role === "STUDENT") {
                        chooseLesson();
                        localStorage.setItem(
                          "util",
                          JSON.stringify({
                            util: 7528,
                          })
                        );
                      }
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      actions.resetForm();
                    }
                  })
                  .catch((error) => {                    
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.username, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });

                      toast.error(error.response.data.validations.password, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });                    
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <Form onSubmit={formikk.handleSubmit}>
                  <FloatingLabel
                    controlId="username"
                    label="User Name"
                    className="mb-4 "
                    style={{ width: "20rem" }}
                  >
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="username"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.username}
                      isInvalid={!!formikk.errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.username}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="password"
                    label="Password"
                    className="mb-4 "
                    style={{ width: "20rem" }}
                  >
                    <Form.Control
                      as="input"
                      type="password"
                      placeholder="Password"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.password}
                      isInvalid={!!formikk.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.password}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <div className="d-grid gap-2 col-6 mx-auto pt-4">
                    <Button
                      variant="primary"
                      className="fw-semibold"
                      onClick={formikk.submitForm}
                    >
                      Login
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        </Card.Body>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default Login;
