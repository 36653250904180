import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Modal,
  Pagination,
} from "react-bootstrap";
import { Formik } from "formik";
import { useStateValue } from "../../context/myContext";
import axiosInstance from "../../service/axiosInstance";
import Select from "react-select";
import * as Yup from "yup";

const schema = Yup.object().shape({
  studentIds: Yup.array().required("Select at least a student"),
  date: Yup.date().required("Select a date"),
  description: Yup.string()
      .required("Enter a description")
      .min(2, "At least 2 characters")
      .max(250, "Max 250 characters"),
  startTime: Yup.string().required("Select the time"),
  stopTime: Yup.string().required("Select the time"),
});

const MeetPage = () => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedOptions02, setSelectedOptions02] = useState();

  const { setMyState } = useStateValue();
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  //Offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Meet Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const [meetPage, setMeetPage] = useState([]);
  const [meetPage2, setMeetPage2] = useState([]);

  const getMeetPage = () => {
    axiosInstance
      .get(
        "/meet/getAllMeetByAdvisorAsPage" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage
      )
      .then((response) => {
        setMeetPage(response.data);
        setMeetPage2(response.data.content);
      });
  };

  useEffect(() => {
    getMeetPage();
  }, [currentPage]);

  const meetList = meetPage2;

  const totalPages = meetPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (currentPage < Math.ceil(meetPage.totalElements / rowsPerPage)) {
      setCurrentPage(Math.ceil(meetPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (currentPage < Math.ceil(meetPage.totalElements / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Get All Meets
  /* const [meets, setMeets] = useState([]);

  const getMeets = () => {
    axiosInstance.get("/meet/getAll").then((response) => {
      setMeets(response.data);
    });
  };

  useEffect(() => {
    getMeets();
  }, []);

  const meetsList = meets; */

  //Edit Meet
  const [editMeets, setEditMeets] = useState({});

  const getMeetForEdit = async (id) => {
    try {
      const response = await axiosInstance
        .get("/meet/getMeetById/" + id)
        .then((response) => {
          setEditMeets(response.data.object);
        });
      handleShow();
    } catch (error) {}
  };

  const date = editMeets.date;
  const description = editMeets.description;
  const startTime = editMeets.startTime;
  const stopTime = editMeets.stopTime;
  const studentId = editMeets.studentId;
  const meetId = editMeets.id;

  //Delete Meet
  const handleDelete = (id) => {
    axiosInstance.delete("/meet/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
        getMeetPage();
      } else {
        toast.error("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  //Get All Students
  const [students, setStudents] = useState([]);

  const getStudents = () => {
    axiosInstance.get("/students/getAll").then((response) => {
      setStudents(response.data);
    });
  };

  useEffect(() => {
    getStudents();
  }, []);

  const studentsList = students;

  {
    studentsList.map((item) => (
      <option className="fw-semibold m-2" value={item.userId} key={item.userId}>
        {item.name} {item.surname}
      </option>
    ));
  }

  const optionList = students.map((items2) => ({
    value: items2.userId,
    label: items2.name + " " + items2.surname,
  }));

  const studentForSearch = selectedOptions
    ? selectedOptions && selectedOptions.map((items) => items.value)
    : "";

  const studentForSearch02 = selectedOptions02
    ? selectedOptions02 && selectedOptions02.map((items) => items.value)
    : "";

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 2463 && data.util !== 6982 && (
        <Navigate to="/login" />
      )}

      {((data && data.util === 2463) || (data && data.util === 6982)) && (
        <div>
          {/* Add Meet Start*/}
          <Container fluid>
            <Card
              className="mt-4 ms-5 me-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                Meet Management
              </Card.Header>
            </Card>
          </Container>
          <Container fluid>
            <Formik
              enableReinitialize
              initialValues={{
                date: "",
                description: "",
                startTime: "",
                stopTime: "",
                studentIds: studentForSearch,
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .post("/meet/save", values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      setMyState({
                        type: "MEET",
                        item: userInfo.object,
                      });
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      actions.resetForm();
                      getMeetPage();
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.date, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      toast.error(error.response.data.validations.description, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.startTime, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.stopTime, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.studentIds, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                    }
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <Card
                  className="mb-5 ms-5 me-5 mt-5 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                  onSubmit={formikk.handleSubmit}
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                    Add Meet
                  </Card.Header>
                  <Card.Body>
                    <>
                      <Form>
                        <Row>
                          <Col lg="4">
                            <Form.Label className="fw-semibold mt-4">
                              Choose Students
                            </Form.Label>
                            <Select
                              options={optionList}
                              placeholder="Select Students"
                              value={selectedOptions}
                              onChange={setSelectedOptions}
                              isSearchable={true}
                              isMulti
                            />
                          </Col>

                          <Col>
                            <Form.Group className="mb-3 pt-4" controlId="date">
                              <Form.Label>Date Of Meet</Form.Label>

                              <Form.Control
                                as="input"
                                type="date"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.date}
                                isInvalid={!!formikk.errors.date}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.date}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3 pt-4"
                              controlId="startTime"
                            >
                              <Form.Label className="fw-semibold mb-2">
                                Start Time
                              </Form.Label>

                              <Form.Control
                                as="input"
                                type="time"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.startTime}
                                isInvalid={!!formikk.errors.startTime}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.startTime}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group
                              className="mb-3 pt-4"
                              controlId="stopTime"
                            >
                              <Form.Label className="fw-semibold mb-2">
                                Stop Time
                              </Form.Label>

                              <Form.Control
                                as="input"
                                type="time"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.stopTime}
                                isInvalid={!!formikk.errors.stopTime}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.stopTime}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col md="auto" className="mt-5">
                            <FloatingLabel
                              controlId="description"
                              label="Description"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Description"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.description}
                                isInvalid={!!formikk.errors.description}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.description}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>

                          <Col className="mx-auto ps-3 pt-5">
                            <div>
                              <Button
                                size="lg"
                                variant="primary"
                                className="fw-semibold"
                                onClick={() => {
                                  formikk.submitForm();
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  </Card.Body>
                </Card>
              )}
            </Formik>
          </Container>
          {/* Add Meet End*/}

          {/* GetAll Meet Start*/}
          <Container fluid>
            <Card
              className="mb-5 ms-5 me-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                Meet List
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>

                      <th>Start Time</th>
                      <th>Stop Time</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {meetList.map((item2) => (
                      <tr key={item2.id}>
                        <td>
                          <span>{item2.date}</span>
                        </td>

                        <td>
                          <span>{item2.startTime}</span>
                        </td>

                        <td>
                          <span>{item2.stopTime}</span>
                        </td>
                        <td>
                          <span>{item2.description}</span>
                        </td>

                        <td>
                          <span>
                            <Button
                              variant="outline-info"
                              className="text-dark"
                              onClick={() => {
                                getMeetForEdit(item2.id);
                              }}
                            >
                              <i className="fa-solid fa-pencil" />
                              &nbsp; Edit
                            </Button>
                          </span>
                        </td>
                        <td>
                          <span>
                            <Button
                              variant="danger"
                              onClick={() => handleDelete(item2.id)}
                            >
                              <i className="fa-solid fa-trash" />
                            </Button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col className="d-flex justify-content-start ps-5">
                    Page {currentPage} of {totalPages}
                  </Col>
                  <Col>
                    <Pagination className="d-flex justify-content-end pe-3">
                      <Pagination.First onClick={showFirstPage} />
                      <Pagination.Prev onClick={showPrevPage} />

                      <Pagination.Item active>{currentPage}</Pagination.Item>

                      <Pagination.Next onClick={showNextPage} />
                      <Pagination.Last onClick={showLastPage} />
                    </Pagination>
                  </Col>
                </Row>
                <ToastContainer />
              </Card.Body>
            </Card>
          </Container>
          {/* GetAll Meet End*/}

          {/* Edit Meet Start*/}
          <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Edit Meet</Modal.Title>
            </Modal.Header>

            <Formik
              enableReinitialize
              initialValues={{
                date: date,
                description: description,
                startTime: startTime,
                stopTime: stopTime,
                studentIds: studentForSearch02,
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .put("/meet/update/" + meetId, values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      setMyState({
                        type: "MEETEDIT",
                        item: userInfo.object,
                      });
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      getMeetPage();
                      handleClose();
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.date, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      toast.error(error.response.data.validations.description, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.startTime, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.stopTime, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.studentIds, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                    }
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <div>
                  <Modal.Body>
                    <Card
                      className="ms-4 me-4 text-center border border-3 shadow-sm bg-body rounded"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Card.Body>
                        <>
                          <Form>
                            <Row>
                              <Col lg="4">
                                <Form.Label className="fw-semibold mt-3">
                                  Choose Students
                                </Form.Label>
                                <Select
                                  options={optionList}
                                  placeholder="Select Students02"
                                  value={selectedOptions}
                                  onChange={setSelectedOptions02}
                                  isSearchable={true}
                                  isMulti
                                />
                              </Col>

                              <Col>
                                <Form.Group
                                  className="mb-3 pt-4"
                                  controlId="date"
                                >
                                  <Form.Label>Date Of Meet</Form.Label>

                                  <Form.Control
                                    as="input"
                                    type="date"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.date}
                                    isInvalid={!!formikk.errors.date}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.date}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group
                                  className="mb-3 mt-4"
                                  controlId="startTime"
                                >
                                  <Form.Label className="fw-semibold mb-2">
                                    Start Time
                                  </Form.Label>

                                  <Form.Control
                                    as="input"
                                    type="time"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.startTime}
                                    isInvalid={!!formikk.errors.startTime}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.startTime}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col>
                                <Form.Group
                                  className="mb-3 mt-4"
                                  controlId="stopTime"
                                >
                                  <Form.Label className="fw-semibold mb-2">
                                    Stop Time
                                  </Form.Label>

                                  <Form.Control
                                    as="input"
                                    type="time"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.stopTime}
                                    isInvalid={!!formikk.errors.stopTime}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.stopTime}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col md="auto" className="mt-5">
                                <FloatingLabel
                                  controlId="description"
                                  label="Description"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Description"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.description}
                                    isInvalid={!!formikk.errors.description}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.description}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      size="lg"
                      variant="primary"
                      className="fw-semibold"
                      onClick={() => {
                        formikk.submitForm();
                      }}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Modal>
          {/* Edit Meet End*/}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default MeetPage;
