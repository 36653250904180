import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, FloatingLabel, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import logo from "../../assets/images/logo.png";

import axiosInstance from "../../service/axiosInstance";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  birthDay: Yup.string().required("Required"),
  birthPlace: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .min(12, "Minimum 12 character (XXX-XXX-XXXX)")
    .required("Required"),
  ssn: Yup.string()
    .min(11, "Minimum 11 character (XXX-XX-XXXX)")
    .required("Required"),
  username: Yup.string().required("Required"),
  password: Yup.string().min(8, "Minimum 8 character").required("Required"),
});

const Register = () => {
  return (
    <div className="page justify-content-center align-items-center">
      <Card
        className="text-center justify-content-center align-items-center shadow-lg p-3 bg-body rounded mt-5 mb-5"
        style={{ width: "30rem" }}
        border="warning"
      >
        <Link to="/">
          <Card.Img
            variant="top"
            style={{ width: "10rem", margin: "-1.3rem 0rem -0.9rem 0rem" }}
            src={logo}
            className="p-4 pb-6"
            alt="logo"
          />
        </Link>

        <Card.Body>
          <Card.Title className="mb-4 fw-semibold shadow-sm bg-body-tertiary">
            Register
          </Card.Title>
          <>
            <Formik
              initialValues={{
                name: "",
                surname: "",
                birthDay: "",
                birthPlace: "",
                gender: "",
                password: "",
                phoneNumber: "",
                ssn: "",
                username: "",
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .post("/guestUser/register", values)
                  .then((response) => {
                    if (response.status === 200) {
                      toast.success(response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      actions.resetForm();
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.name, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      toast.error(error.response.data.validations.surname, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.gender, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.birthPlace, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.username, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.phoneNumber, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.ssn, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.birthDay, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                    }
                  });

                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <Form onSubmit={formikk.handleSubmit}>
                  <FloatingLabel
                    controlId="name"
                    label="Name"
                    className="mb-4 "
                  >
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="Name"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.name}
                      isInvalid={!!formikk.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.name}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="surname"
                    label="Surname"
                    className="mb-4 "
                  >
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="Surname"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.surname}
                      isInvalid={!!formikk.errors.surname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.surname}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="birthPlace"
                    label="Birth Place"
                    className="mb-4 "
                  >
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="Birth Place"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.birthPlace}
                      isInvalid={!!formikk.errors.birthPlace}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.birthPlace}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="phoneNumber"
                    label="Phone (XXX-XXX-XXXX)"
                    className="mb-4 "
                  >
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="Phone Number"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.phoneNumber}
                      isInvalid={!!formikk.errors.phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.phoneNumber}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <Col>
                    <Form.Label>Gender</Form.Label>
                  </Col>
                  <Form.Check
                    inline
                    label="Female"
                    name="gender"
                    type="radio"
                    onChange={formikk.handleChange}
                    onBlur={formikk.handleBlur}
                    value="FEMALE"
                  />

                  <Form.Check
                    inline
                    label="Male"
                    name="gender"
                    type="radio"
                    onChange={formikk.handleChange}
                    onBlur={formikk.handleBlur}
                    value="MALE"
                  />

                  <Form.Group className="mb-3 pt-4" controlId="birthDay">
                    <Form.Label>Date Of Birth</Form.Label>

                    <Form.Control
                      as="input"
                      type="date"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.birthDay}
                      isInvalid={!!formikk.errors.birthDay}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.birthDay}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <FloatingLabel
                    controlId="ssn"
                    label="Ssn (XXX-XX-XXXX)"
                    className="mb-4 "
                  >
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="ssn"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.ssn}
                      isInvalid={!!formikk.errors.ssn}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.ssn}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="username"
                    label="User Name"
                    className="mb-4 "
                  >
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="username"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.username}
                      isInvalid={!!formikk.errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.username}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="password"
                    label="Password"
                    className="mb-4 "
                  >
                    <Form.Control
                      as="input"
                      type="password"
                      placeholder="Password"
                      onChange={formikk.handleChange}
                      onBlur={formikk.handleBlur}
                      value={formikk.values.password}
                      isInvalid={!!formikk.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formikk.errors.password}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <div className="d-grid gap-2 col-6 mx-auto pt-4">
                    <Button
                      variant="primary"
                      className="fw-semibold"
                      onClick={formikk.submitForm}
                    >
                      Register
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
          <ToastContainer />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Register;
