import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { useStateValue } from "../../context/myContext";

const Logout = () => {
  const { setMyData } = useStateValue();

  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate("/login"), [navigate]);
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const handleYes = () => {
    localStorage.clear();
    setMyData({
      type: "LOGOUT",
      item: null,
    });
    handleOnClick();
  };
  const handleNo = () => {
    goBack();
  };

  return (
    <div className="page justify-content-center align-items-center">
      <Card
        className="text-center justify-content-center align-items-center shadow-lg p-3 mb-4 bg-body rounded"
        style={{ width: "30rem" }}
        border="warning"
      >
        <Card.Body>
          <div className="col-12 align-items-center">
            <h1> Are you really want to logout ? </h1>
          </div>
        </Card.Body>
        <div className="container">
          <div className="row">
            <div className="d-grid gap-2 col-6 mx-auto">
              <Button variant="warning" onClick={handleYes}>
                Yes
              </Button>
            </div>
            <div className="d-grid gap-2 col-6 mx-auto">
              <Button variant="info" onClick={handleNo}>
                No
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Logout;
