import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Modal,
  Pagination,
} from "react-bootstrap";
import { Formik } from "formik";
import { useStateValue } from "../../context/myContext";
import axiosInstance from "../../service/axiosInstance";
import * as Yup from "yup";

const schema = Yup.object().shape({
  lessonId: Yup.string().required("Select lesson"),
  studentId: Yup.string().required("Select student"),
  educationTermId: Yup.number().required("Select education term"),
  absentee: Yup.number().required("Enter absentee count in days"),
  midtermExam: Yup.number().required("Enter midterm exam result"),
  finalExam: Yup.number()
    .min(0, "Min 0.0")
    .max(100, "Max 100.0")
    .required("Enter final exam result"),
  infoNote: Yup.string()
    .min(10, "At least 10 char")
    .max(200, "At most 200 char")
    .required("Enter info note"),
});

const StudentInfoPage = () => {
  const { setMyState } = useStateValue();
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  //Offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Student Info Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const [studentInfoPage, setStudentInfoPage] = useState([]);
  const [studentInfoPage2, setStudentInfoPage2] = useState([]);

  const getStudentInfoPage = () => {
    axiosInstance
      .get(
        "/studentInfo/getAllForTeacher" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage
      )
      .then((response) => {
        setStudentInfoPage(response.data);
        setStudentInfoPage2(response.data.content);
      });
  };

  useEffect(() => {
    getStudentInfoPage();
  }, [currentPage]);

  const studentInfoList = studentInfoPage2;

  const totalPages = studentInfoPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (currentPage < Math.ceil(studentInfoPage.totalElements / rowsPerPage)) {
      setCurrentPage(Math.ceil(studentInfoPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (currentPage < Math.ceil(studentInfoPage.totalElements / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Get All StudentsInfo
  /* const [studentInfo, setStudentInfo] = useState([]);

  const getStudentInfo = () => {
    axiosInstance.get("/studentInfo/getAll").then((response) => {
      setStudentInfo(response.data);
    });
  };

  useEffect(() => {
    getStudentInfo();
  }, []);

  const studentInfoList = studentInfo; */

  //Edit Student Info
  const [editStudentInfo, setEditStudentInfo] = useState({});

  const getStudentInfoForEdit = async (id) => {
    try {
      const response = await axiosInstance
        .get("/studentInfo/get/" + id)
        .then((response) => {
          setEditStudentInfo(response.data);
        });
      handleShow();
    } catch (error) {}
  };

  const absentee = editStudentInfo.absentee;
  const finalExam = editStudentInfo.finalExam;
  const infoNote = editStudentInfo.infoNote;
  const studentId = editStudentInfo.studentResponse?.userId;
  const midtermExam = editStudentInfo.midtermExam;
  const userId = editStudentInfo.id;

  //Delete Student Info
  const handleDelete = (id) => {
    axiosInstance.delete("/studentInfo/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
        getStudentInfoPage();
      } else {
        toast.error("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  //GetAll Lessons For Student Info
  const [lessons, setLessons] = useState([]);

  const getAllLesson = () => {
    axiosInstance
      .get("/lessons/getAll")
      .then((response) => {
        setLessons(response.data);
      });
  };

  useEffect(() => {
    getAllLesson();
  }, []);

  const lessonList = lessons;

  //Get All Students
  const [students, setStudents] = useState([]);

  const getStudents = () => {
    axiosInstance.get("/students/getAll").then((response) => {
      setStudents(response.data);
    });
  };

  useEffect(() => {
    getStudents();
  }, []);

  const studentsList = students;

  //Get All Education Term
  const [educationTerms, setEducationTerms] = useState([]);

  const getEducationTerms = () => {
    axiosInstance.get("/educationTerms/getAll").then((response) => {
      setEducationTerms(response.data);
    });
  };

  useEffect(() => {
    getEducationTerms();
  }, []);

  const educationTermsList = educationTerms;

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 2463 && data.util !== 6982 && (
        <Navigate to="/login" />
      )}

      {((data && data.util === 2463) || (data && data.util === 6982)) && (
        <div>
          {/* Add Student Info Start*/}
          <Container fluid>
            <Card
              className="mt-4 ms-5 me-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                Student Info Management
              </Card.Header>
            </Card>
          </Container>
          <Container fluid>
            <Formik
              initialValues={{
                lessonId: "",
                studentId: "",
                absentee: "",
                finalExam: "",
                infoNote: "",
                midtermExam: "",
                educationTermId: "",
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .post("/studentInfo/save", values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      setMyState({
                        type: "STUDENTINFO",
                        item: userInfo.object,
                      });
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      actions.resetForm();
                      getStudentInfoPage();
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.lessonId, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      toast.error(error.response.data.validations.studentId, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.absentee, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.finalExam, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.infoNote, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.midtermExam, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(
                        error.response.data.validations.educationTermId,
                        {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        }
                      );
                    }
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <Card
                  className="m-5 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                  onSubmit={formikk.handleSubmit}
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                    Add Student Info
                  </Card.Header>
                  <Card.Body>
                    <>
                      <Form>
                        <Row>
                          <Col lg="4">
                            <Form.Label className="fw-semibold m-2">
                              Choose Lesson
                            </Form.Label>
                            <Form.Select
                              onChange={formikk.handleChange}
                              aria-label="Default select example"
                              id="lessonId"
                            >
                              <option>Choose Lesson</option>
                              {lessonList.map((item, index) => (
                                <option
                                  className="fw-semibold m-2"
                                  value={item.lessonId}
                                  key={item.lessonId}
                                >
                                  {item.lessonName}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>

                          <Col lg="4">
                            <Form.Label className="fw-semibold m-2">
                              Choose Student
                            </Form.Label>
                            <Form.Select
                              onChange={formikk.handleChange}
                              aria-label="Default select example"
                              id="studentId"
                            >
                              <option>Choose Student</option>
                              {studentsList.map((item) => (
                                <option
                                  className="fw-semibold m-2"
                                  value={item.userId}
                                  key={item.userId}
                                >
                                  {item.name} {item.surname}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col lg="4">
                            <Form.Label className="fw-semibold m-2">
                              Choose Education Term
                            </Form.Label>
                            <Form.Select
                              onChange={formikk.handleChange}
                              aria-label="Default select example"
                              id="educationTermId"
                            >
                              <option>Choose Education Term</option>
                              {educationTermsList.map((item3) => (
                                <option
                                  className="fw-semibold m-2"
                                  value={item3.id}
                                  key={item3.id}
                                >
                                  {item3.term}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>

                          <Col md="auto" className="mt-5">
                            <FloatingLabel
                              controlId="absentee"
                              label="Absentee"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Absentee"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.absentee}
                                isInvalid={!!formikk.errors.absentee}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.absentee}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>

                          <Col md="auto" className="mt-5">
                            <FloatingLabel
                              controlId="midtermExam"
                              label="Midterm Exam"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Midterm Exam"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.midtermExam}
                                isInvalid={!!formikk.errors.midtermExam}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.midtermExam}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>

                          <Col md="auto" className="mt-5">
                            <FloatingLabel
                              controlId="finalExam"
                              label="Final Exam"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Final Exam"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.finalExam}
                                isInvalid={!!formikk.errors.finalExam}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.finalExam}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>

                          <Col md="auto" className="mt-5">
                            <FloatingLabel
                              controlId="infoNote"
                              label="Info Note"
                              className="mb-4 "
                            >
                              <Form.Control
                                as="input"
                                type="text"
                                placeholder="Info Note"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value={formikk.values.infoNote}
                                isInvalid={!!formikk.errors.infoNote}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formikk.errors.infoNote}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>

                          <Col className="mx-auto ms-3 mt-5">
                            <div>
                              <Button
                                size="lg"
                                variant="primary"
                                className="fw-semibold"
                                onClick={() => {
                                  formikk.submitForm();
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  </Card.Body>
                </Card>
              )}
            </Formik>
          </Container>
          {/* Add Student Info End*/}

          {/* Student Info List Start*/}
          <Container fluid>
            <Card
              className="mb-5 ms-5 me-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                Student Info List
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Lesson Name</th>
                      <th>Absentee</th>
                      <th>Midterm Exam</th>
                      <th>Final Exam</th>
                      <th>Note</th>
                      <th>Info Note</th>
                      <th>Average</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentInfoList.map((item2) => (
                      <tr key={item2.id}>
                        <td>
                          <span>
                            {item2.studentResponse.name}{" "}
                            {item2.studentResponse.surname}
                          </span>
                        </td>

                        <td>
                          <span>{item2.lessonName}</span>
                        </td>

                        <td>
                          <span>{item2.absentee}</span>
                        </td>
                        <td>
                          <span>{item2.midtermExam}</span>
                        </td>
                        <td>
                          <span>{item2.finalExam}</span>
                        </td>
                        <td>
                          <span>{item2.note}</span>
                        </td>
                        <td>
                          <span>{item2.infoNote}</span>
                        </td>
                        <td>
                          <span>{item2.average}</span>
                        </td>
                        <td>
                          <span>
                            <Button
                              variant="outline-info"
                              className="text-dark"
                              onClick={() => {
                                getStudentInfoForEdit(item2.id);
                              }}
                            >
                              <i className="fa-solid fa-pencil" />
                              &nbsp; Edit
                            </Button>
                          </span>
                        </td>
                        <td>
                          <span>
                            <Button
                              variant="danger"
                              onClick={() => handleDelete(item2.id)}
                            >
                              <i className="fa-solid fa-trash" />
                            </Button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col className="d-flex justify-content-start ps-5">
                    Page {currentPage} of {totalPages}
                  </Col>
                  <Col>
                    <Pagination className="d-flex justify-content-end pe-3">
                      <Pagination.First onClick={showFirstPage} />
                      <Pagination.Prev onClick={showPrevPage} />

                      <Pagination.Item active>{currentPage}</Pagination.Item>

                      <Pagination.Next onClick={showNextPage} />
                      <Pagination.Last onClick={showLastPage} />
                    </Pagination>
                  </Col>
                </Row>
                <ToastContainer />
              </Card.Body>
            </Card>
          </Container>
          {/* Student Info List End*/}

          {/* Edit Student Info Start*/}
          <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                Edit Student Info
              </Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{
                lessonId: "",
                studentId: studentId,
                absentee: absentee,
                finalExam: finalExam,
                infoNote: infoNote,
                midtermExam: midtermExam,
                educationTermId: "",
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                axiosInstance
                  .put("/studentInfo/update/" + userId, values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      setMyState({
                        type: "STUDENTINFOEDIT",
                        item: userInfo.object,
                      });
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      getStudentInfoPage();
                      handleClose()
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(error.response.data.validations.lessonId, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      toast.error(error.response.data.validations.studentId, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.absentee, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.finalExam, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.infoNote, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(error.response.data.validations.midtermExam, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      toast.error(
                        error.response.data.validations.educationTermId,
                        {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        }
                      );
                    }
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <div>
                  <Modal.Body>
                    <Card
                      className="ms-4 me-4 text-center border border-3 shadow-sm bg-body rounded"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Card.Body>
                        <>
                          <Form>
                            <Row>
                              <Col md="auto" className="mt-2">
                                <Col>
                                  <Form.Label className="fw-semibold m-2">
                                    Name
                                  </Form.Label>
                                </Col>
                                <span>
                                  {editStudentInfo.studentResponse.name}
                                  &nbsp;
                                  {editStudentInfo.studentResponse.surname}
                                </span>
                              </Col>
                              <Col md="auto" className="mt-2">
                                <Form.Label className="fw-semibold m-2">
                                  Choose Lesson
                                </Form.Label>
                                <Form.Select
                                  onChange={formikk.handleChange}
                                  aria-label="Default select example"
                                  id="lessonId"
                                >
                                  <option>Choose Lesson</option>
                                  {lessonList.map((item) => (
                                    <option
                                      className="fw-semibold m-2"
                                      value={item.lessonId}
                                      key={item.lessonId}
                                    >
                                      {item.lessonName}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                              <Col md="auto" className="mt-2">
                                <Form.Label className="fw-semibold m-2">
                                  Choose Education Term
                                </Form.Label>
                                <Form.Select
                                  onChange={formikk.handleChange}
                                  aria-label="Default select example"
                                  id="educationTermId"
                                >
                                  <option>Choose Education Term</option>
                                  {educationTermsList.map((item3) => (
                                    <option
                                      className="fw-semibold m-2"
                                      value={item3.id}
                                      key={item3.id}
                                    >
                                      {item3.term}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>

                              <Col md="auto" className="mt-4">
                                <FloatingLabel
                                  controlId="absentee"
                                  label="Absentee"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Absentee"
                                    onChange={(e)=>{formikk.handleChange(e); console.log(formikk)}}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.absentee}
                                    isInvalid={!!formikk.errors.absentee}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.absentee}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>

                              <Col md="auto" className="mt-4">
                                <FloatingLabel
                                  controlId="midtermExam"
                                  label="Midterm Exam"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Midterm Exam"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.midtermExam}
                                    isInvalid={!!formikk.errors.midtermExam}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.midtermExam}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>

                              <Col md="auto" className="mt-4">
                                <FloatingLabel
                                  controlId="finalExam"
                                  label="Final Exam"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Final Exam"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.finalExam}
                                    isInvalid={!!formikk.errors.finalExam}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.finalExam}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>

                              <Col md="auto" className="mt-4">
                                <FloatingLabel
                                  controlId="infoNote"
                                  label="Info Note"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Info Note"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.infoNote}
                                    isInvalid={!!formikk.errors.infoNote}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.infoNote}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      size="lg"
                      variant="primary"
                      className="fw-semibold"
                      onClick={() => {
                        formikk.submitForm();
                      }}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Modal>
          {/* Edit Student Info End*/}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default StudentInfoPage;
