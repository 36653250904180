import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { Formik } from "formik";
import { useStateValue } from "../../../context/myContext";
import axiosInstance from "../../../service/axiosInstance";
import Select from "react-select";
import * as Yup from "yup";

const schema = Yup.object().shape({
  term: Yup.string()
    .required("Enter the term")
    .oneOf(["SPRING_SEMESTER", "FALL_SEMESTER"], "Select a term"),
  startDate: Yup.date().required("Select the start date"),
  endDate: Yup.date().required("Select the end date"),
  lastRegistrationDate: Yup.date().required("Select the last registration date"),
});

const schema2 = Yup.object().shape({
  lessonName: Yup.string()
    .required("Enter the lesson name")
    .min(2, "At least 2 characters")
    .max(25, "At most 25 characters"),
  creditScore: Yup.number()
    .required("Enter the credit score")
});

const schema3 = Yup.object().shape({
  // lessonIdList: Yup.string().required("Select the lesson"),
  day: Yup.string().required("Select the day"),
  startTime: Yup.string().required("Select the start time"),
  stopTime: Yup.string().required("Select the stop time"),
  educationTermId: Yup.string().required("Select the education term"),
});

const LessonPage = () => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedOptions02, setSelectedOptions02] = useState();

  const { setMyState } = useStateValue();
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  //Tab
  const [key, setKey] = useState("educationTerm");

  //GetAll Lessons
  const [lessons, setLessons] = useState([]);

  const getLessons = () => {
    axiosInstance.get("/lessons/getAll").then((response) => {
      setLessons(response.data);
    });
  };

  useEffect(() => {
    getLessons();
  }, []);

  const list = lessons;

  const optionList = lessons.map((items2) => ({
    value: items2.lessonId,
    label: items2.lessonName,
  }));

  const lessonForSearch = selectedOptions
    ? selectedOptions && selectedOptions.map((items) => items.value)
    : "";

  //Delete Lesson
  const handleDelete = (id) => {
    axiosInstance.delete("/lessons/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
        getLessonPage();
      } else {
        toast.error("Unable to Deleted the lesson", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  //GetAll Lessons Program
  const [lessonProgramUnassigned, setLessonProgramUnassigned] = useState([]);

  const getLessonProgramUnassigned = () => {
    axiosInstance.get("/lessonPrograms/getAllUnassigned").then((response) => {
      setLessonProgramUnassigned(response.data);
    });
  };

  useEffect(() => {
    getLessonProgramUnassigned();
  }, []);

  const list2 = lessonProgramUnassigned;

  // Education Term Pagination
  const [currentTPage, setCurrentTPage] = useState(1);
  const rowsPerTPage = "20";
  const searchTSort = "startDate";
  const searchTtype = "desc";
  const [termPage, setTermPage] = useState([]);
  const [termPage2, setTermPage2] = useState([]);

  const getTermPage = () => {
    axiosInstance
      .get(
        "/educationTerms/search" +
          "?page=" +
          (currentTPage - 1) +
          "&size=" +
          rowsPerTPage +
          "&sort=" +
          searchTSort +
          "&type=" +
          searchTtype
      )
      .then((response) => {
        setTermPage(response.data);
        setTermPage2(response.data.content);
      });
  };

  useEffect(() => {
    getTermPage();
  }, [currentTPage]);

  const termList = termPage2;

  const totalTPages = termPage.totalPages;

  const showFirstTPage = () => {
    let firstPage = 1;
    if (currentTPage > firstPage) {
      setCurrentTPage(firstPage);
    }
  };

  const showLastTPage = () => {
    if (currentTPage < Math.ceil(termPage.totalElements / rowsPerTPage)) {
      setCurrentTPage(Math.ceil(termPage.totalElements / rowsPerTPage));
    }
  };

  const showNextTPage = () => {
    if (currentTPage < Math.ceil(termPage.totalElements / rowsPerTPage)) {
      setCurrentTPage(currentTPage + 1);
    }
  };

  const showPrevTPage = () => {
    let prevPage = 1;
    if (currentTPage > prevPage) {
      setCurrentTPage(currentTPage - prevPage);
    }
  };

  //Delete Education Term
  const handleDelete3 = (id) => {
    axiosInstance.delete("/educationTerms/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
        getTermPage();
        getLessonProgramPage();
      } else {
        toast.error("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  //Get All Education Term
  const [educationTerms, setEducationTerms] = useState([]);

  const getEducationTerms = () => {
    axiosInstance.get("/educationTerms/getAll").then((response) => {
      setEducationTerms(response.data);
    });
  };

  useEffect(() => {
    getEducationTerms();
  }, []);

  const educationTermsList = educationTerms;

  // Lesson Pagination
  const [currentLPage, setCurrentLPage] = useState(1);
  const rowsPerLPage = "20";
  const searchLSort = "lessonId";
  const searchLtype = "desc";
  const [lessonPage, setLessonPage] = useState([]);
  const [lessonPage2, setLessonPage2] = useState([]);

  const getLessonPage = () => {
    axiosInstance
      .get(
        "/lessons/search" +
          "?page=" +
          (currentLPage - 1) +
          "&size=" +
          rowsPerLPage +
          "&sort=" +
          searchLSort +
          "&type=" +
          searchLtype
      )
      .then((response) => {
        setLessonPage(response.data);
        setLessonPage2(response.data.content);
      });
  };

  useEffect(() => {
    getLessonPage();
  }, [currentLPage]);

  const list4 = lessonPage2;

  const totalLPages = lessonPage.totalPages;

  const showFirstLPage = () => {
    let firstPage = 1;
    if (currentLPage > firstPage) {
      setCurrentLPage(firstPage);
    }
  };

  const showLastLPage = () => {
    if (currentLPage < Math.ceil(lessonPage.totalElements / rowsPerLPage)) {
      setCurrentLPage(Math.ceil(lessonPage.totalElements / rowsPerLPage));
    }
  };

  const showNextLPage = () => {
    if (currentLPage < Math.ceil(lessonPage.totalElements / rowsPerLPage)) {
      setCurrentLPage(currentLPage + 1);
    }
  };

  const showPrevLPage = () => {
    let prevPage = 1;
    if (currentLPage > prevPage) {
      setCurrentLPage(currentLPage - prevPage);
    }
  };

  // Lesson Program Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const searchSort = "id";
  const searchtype = "desc";
  const [lessonProgramPage, setLessonProgramPage] = useState([]);
  const [lessonProgramPage2, setLessonProgramPage2] = useState([]);

  const getLessonProgramPage = () => {
    axiosInstance
      .get(
        "/lessonPrograms/search" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage +
          "&sort=" +
          searchSort +
          "&type=" +
          searchtype
      )
      .then((response) => {
        setLessonProgramPage(response.data);
        setLessonProgramPage2(response.data.content);
      });
  };

  useEffect(() => {
    getLessonProgramPage();
  }, [currentPage]);

  const lessonProgramPageList = lessonProgramPage2;

  const totalPages = lessonProgramPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (
      currentPage < Math.ceil(lessonProgramPage.totalElements / rowsPerPage)
    ) {
      setCurrentPage(Math.ceil(lessonProgramPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (
      currentPage < Math.ceil(lessonProgramPage.totalElements / rowsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Delete Lesson Program
  const handleDelete2 = (id) => {
    axiosInstance.delete("/lessonPrograms/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
        getLessonProgramPage();
        getLessonProgramUnassigned();
      } else {
        toast.error("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  //Get All Teachers
  const [teachers, setTeachers] = useState([]);

  const getTeachers = () => {
    axiosInstance.get("/teachers/getAll").then((response) => {
      setTeachers(response.data);
    });
  };

  useEffect(() => {
    getTeachers();
  }, []);

  const teacherList = teachers;

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 5487 && data.util !== 6982 && (
        <Navigate to="/login" />
      )}

      {((data && data.util === 5487) || (data && data.util === 6982)) && (
        <div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-2 fw-bold fs-4"
            justify
          >
            <Tab eventKey="educationTerm" title="Education Term">
              <Container fluid>
                <Card
                  className="mt-4 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                    Education Term Management
                  </Card.Header>
                </Card>
              </Container>
              {/*Add Education Term Start*/}
              <Container fluid>
                <Formik
                  initialValues={{
                    startDate: "",
                    endDate: "",
                    lastRegistrationDate: "",
                    term: "",
                  }}
                  validationSchema={schema}
                  onSubmit={(values, actions) => {
                    axiosInstance
                      .post("/educationTerms/save", values)
                      .then((response) => {
                        if (response.status === 200) {
                          const userInfo = response.data;
                          setMyState({
                            type: "EDUCATIONTERM",
                            item: userInfo.object,
                          });
                          toast.success(userInfo.message, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          actions.resetForm();
                          getTermPage();
                          getLessonProgramPage();
                          getEducationTerms();
                        }
                      })
                      .catch((error) => {
                        {
                          !error.response.data.validations &&
                            toast.error(error.response.data.message, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                        }
                        {
                          error.response.data.validations &&
                            toast.error(
                              error.response.data.validations.endDate,
                              {
                                position: "top-center",
                                theme: "colored",
                                hideProgressBar: true,
                                autoClose: 1000,
                                closeOnClick: true,
                              }
                            );
                          error.response.data.validations &&
                            toast.error(
                              error.response.data.validations
                                .lastRegistrationDate,
                              {
                                position: "top-center",
                                theme: "colored",
                                hideProgressBar: true,
                                autoClose: 1000,
                                closeOnClick: true,
                              }
                            );
                          error.response.data.validations &&
                            toast.error(
                              error.response.data.validations.startDate,
                              {
                                position: "top-center",
                                theme: "colored",
                                hideProgressBar: true,
                                autoClose: 1000,
                                closeOnClick: true,
                              }
                            );
                          error.response.data.validations &&
                            toast.error(error.response.data.validations.term, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                        }
                      });
                    actions.setSubmitting(false);
                  }}
                >
                  {(formikk) => (
                    <Card
                      className="ms-3 me-3 mb-5 mt-5 text-center border border-3 shadow-sm bg-body rounded"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Col>
                        <Card.Header
                          className="fw-bold p-3"
                          bg="primary"
                          as="h5"
                        >
                          Add Education Term
                        </Card.Header>
                        <Card.Body>
                          <>
                            <Form>
                              <Row>
                                <Col>
                                  <Form.Label className="fw-semibold">
                                    Choose Education Term
                                  </Form.Label>
                                  <Form.Select
                                    onChange={formikk.handleChange}
                                    id="term"
                                    aria-label="Default select example"
                                    className="mb-4"
                                  >
                                    <option>Choose Education Term</option>
                                    <option value="FALL_SEMESTER">
                                      FALL SEMESTER
                                    </option>
                                    <option value="SPRING_SEMESTER">
                                      SPRING SEMESTER
                                    </option>
                                  </Form.Select>
                                </Col>

                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="startDate"
                                  >
                                    <Form.Label className="fw-semibold mb-2">
                                      Start Date
                                    </Form.Label>

                                    <Form.Control
                                      as="input"
                                      type="date"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={formikk.values.startDate}
                                      isInvalid={!!formikk.errors.startDate}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formikk.errors.startDate}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="endDate"
                                  >
                                    <Form.Label className="fw-semibold mb-2">
                                      End Date
                                    </Form.Label>

                                    <Form.Control
                                      as="input"
                                      type="date"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={formikk.values.endDate}
                                      isInvalid={!!formikk.errors.endDate}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formikk.errors.endDate}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lastRegistrationDate"
                                  >
                                    <Form.Label className="fw-semibold mb-2">
                                      Last Registration Date
                                    </Form.Label>

                                    <Form.Control
                                      as="input"
                                      type="date"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={
                                        formikk.values.lastRegistrationDate
                                      }
                                      isInvalid={
                                        !!formikk.errors.lastRegistrationDate
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formikk.errors.lastRegistrationDate}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col className="mx-auto p-3">
                                  <div>
                                    <Button
                                      size="lg"
                                      variant="primary"
                                      className="fw-semibold"
                                      onClick={() => {
                                        formikk.submitForm();
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        </Card.Body>
                      </Col>
                      {/*Add Education Term End*/}
                    </Card>
                  )}
                </Formik>

                {/*Education Term List Start*/}

                <Card
                  className="mt-5 ms-3 me-3 mb-4 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Col>
                    <Card.Header className="fw-bold p-3" as="h5">
                      Education Term List
                    </Card.Header>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Education Term</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Last Registration Date</th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {termList.map((item3) => (
                          <tr key={item3.id}>
                            <td>
                              <span>{item3.term}</span>
                            </td>

                            <td>
                              <span>{item3.startDate}</span>
                            </td>

                            <td>
                              <span>{item3.endDate}</span>
                            </td>

                            <td>
                              <span>{item3.lastRegistrationDate}</span>
                            </td>
                            {/* <td>
                                <span>
                                  <Button
                                    variant="danger"
                                    onClick={() => handleDelete3(item3.id)}
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </Button>
                                </span>
                              </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Row>
                      <Col className="d-flex justify-content-start ps-5">
                        Page {currentTPage} of {totalTPages}
                      </Col>
                      <Col>
                        <Pagination className="d-flex justify-content-end pe-3">
                          <Pagination.First onClick={showFirstTPage} />
                          <Pagination.Prev onClick={showPrevTPage} />

                          <Pagination.Item active>
                            {currentTPage}
                          </Pagination.Item>

                          <Pagination.Next onClick={showNextTPage} />
                          <Pagination.Last onClick={showLastTPage} />
                        </Pagination>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Container>
              {/*Education Term List End*/}
            </Tab>

            <Tab eventKey="lessonsList" title="Lessons">
              <Container fluid>
                <Card
                  className="mt-4 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                    Lesson Management
                  </Card.Header>
                </Card>
              </Container>
              {/*Add Lesson Start*/}
              <Container fluid>
                <Formik
                  initialValues={{
                    lessonName: "",
                    isCompulsory: "false",
                    creditScore: "",
                  }}
                  validationSchema={schema2}
                  onSubmit={(values, actions) => {
                    axiosInstance
                      .post("/lessons/save", values)
                      .then((response) => {
                        if (response.status === 200) {
                          const userInfo = response.data;
                          setMyState({
                            type: "LESSON",
                            item: userInfo.object,
                          });
                          toast.success(userInfo.message, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          actions.resetForm();
                          getLessonPage();
                          getLessons();
                        }
                      })
                      .catch((error) => {
                        {
                          !error.response.data.validations &&
                            toast.error(error.response.data.message, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                        }
                        {
                          error.response.data.validations &&
                            toast.error(
                              error.response.data.validations.lessonName,
                              {
                                position: "top-center",
                                theme: "colored",
                                hideProgressBar: true,
                                autoClose: 1000,
                                closeOnClick: true,
                              }
                            );
                          toast.error(
                            error.response.data.validations.isCompulsory,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.creditScore,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                        }
                      });
                    actions.setSubmitting(false);
                  }}
                >
                  {(formikk) => (
                    <Card
                      className="ms-3 me-3 mb-5 mt-5 text-center border border-3 shadow-sm bg-body rounded"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Col>
                        <Card.Header
                          className="fw-bold p-3"
                          bg="primary"
                          as="h5"
                        >
                          Add Lesson
                        </Card.Header>
                        <Card.Body>
                          <>
                            <Form>
                              <Row>
                                <Col lg={3}>
                                  <FloatingLabel
                                    controlId="lessonName"
                                    label="Lesson Name"
                                    className="mb-4"
                                  >
                                    <Form.Control
                                      as="input"
                                      type="text"
                                      placeholder="Lesson Name"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={formikk.values.lessonName}
                                      isInvalid={!!formikk.errors.lessonName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formikk.errors.lessonName}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col lg={2} className="mt-3 mb-3">
                                  <Form.Check
                                    type="checkbox"
                                    id="isCompulsory"
                                    label="Compulsory"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value="true"
                                  />
                                </Col>
                                <Col lg={3}>
                                  <FloatingLabel
                                    controlId="creditScore"
                                    label="Credit Score"
                                    className="mb-4 "
                                  >
                                    <Form.Control
                                      as="input"
                                      type="text"
                                      placeholder="Credit Score"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={formikk.values.creditScore}
                                      isInvalid={!!formikk.errors.creditScore}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formikk.errors.creditScore}
                                    </Form.Control.Feedback>
                                  </FloatingLabel>
                                </Col>
                                <Col className="mx-auto mt-1 ms-4 mb-1">
                                  <div>
                                    <Button
                                      size="lg"
                                      variant="primary"
                                      className="fw-semibold"
                                      onClick={() => {
                                        formikk.submitForm();
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        </Card.Body>
                      </Col>
                      {/*Add Lesson End*/}
                    </Card>
                  )}
                </Formik>

                {/*Lesson List Start*/}
                <Card
                  className="mt-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Col>
                    <Card.Header className="fw-bold p-3" as="h5">
                      Lesson List
                    </Card.Header>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Lesson Name</th>
                          <th>Compulsory</th>
                          <th>Credit Score</th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {list4.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <span>{item.lessonName}</span>
                            </td>

                            <td>
                              <span>
                                {(item.isCompulsory === true && "Yes") ||
                                  (item.isCompulsory === false && "No")}
                              </span>
                            </td>

                            <td>
                              <span>{item.creditScore}</span>
                            </td>

                            <td>
                              <span>
                                <Button
                                  variant="danger"
                                  onClick={() => handleDelete(item.lessonId)}
                                >
                                  <i className="fa-solid fa-trash" />
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Row>
                      <Col className="d-flex justify-content-start ps-5">
                        Page {currentLPage} of {totalLPages}
                      </Col>
                      <Col>
                        <Pagination className="d-flex justify-content-end pe-3">
                          <Pagination.First onClick={showFirstLPage} />
                          <Pagination.Prev onClick={showPrevLPage} />

                          <Pagination.Item active>
                            {currentLPage}
                          </Pagination.Item>

                          <Pagination.Next onClick={showNextLPage} />
                          <Pagination.Last onClick={showLastLPage} />
                        </Pagination>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Container>
            </Tab>
            {/*Lesson List End*/}

            {/*Add Lesson Program Start*/}
            <Tab eventKey="lessonProgram" title="Lesson Program">
              <Container fluid>
                <Card
                  className="mt-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                    Lesson Program Management
                  </Card.Header>
                </Card>
              </Container>
              <Container fluid>
                <Formik
                  enableReinitialize
                  initialValues={{
                    lessonIdList: lessonForSearch,
                    day: "",
                    startTime: "",
                    stopTime: "",
                    educationTermId: "",
                  }}
                  validationSchema={schema3}
                  onSubmit={(values, actions) => {
                    axiosInstance
                      .post("/lessonPrograms/save", values)
                      .then((response) => {
                        if (response.status === 200) {
                          const userInfo = response.data;
                          setMyState({
                            type: "LESSON",
                            item: userInfo.object,
                          });
                          toast.success(userInfo.message, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          actions.resetForm();
                          getLessonProgramPage();
                          getLessonProgramUnassigned();
                        }
                      })
                      .catch((error) => {
                        {
                          !error.response.data.validations &&
                            toast.error(error.response.data.message, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                        }
                        {
                          error.response.data.validations &&
                            toast.error(
                              error.response.data.validations.startTime,
                              {
                                position: "top-center",
                                theme: "colored",
                                hideProgressBar: true,
                                autoClose: 1000,
                                closeOnClick: true,
                              }
                            );
                          toast.error(
                            error.response.data.validations.stopTime,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.educationTermId,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.lessonIdList,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(error.response.data.validations.day, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                        }
                      });
                    actions.setSubmitting(false);
                  }}
                >
                  {(formikk) => (
                    <Card
                      className="mt-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                        Add Lesson Program
                      </Card.Header>
                      <Card.Body>
                        <>
                          <Form>
                            <Row>
                              <Col lg={6}>
                                <Form.Label className="fw-semibold m-2">
                                  Choose Lessons
                                </Form.Label>
                                <Select
                                  options={optionList}
                                  placeholder="Select lesson"
                                  value={selectedOptions}
                                  onChange={setSelectedOptions}
                                  isSearchable={true}
                                  isMulti
                                />
                                <Form.Select
                                  onChange={formikk.handleChange}
                                  aria-label="Default select example"
                                  id="educationTermId"
                                  className="mt-5"
                                >
                                  <option>Choose Education Term</option>
                                  {educationTermsList.map((item3) => (
                                    <option
                                      className="fw-semibold m-2"
                                      value={item3.id}
                                      key={item3.id}
                                    >
                                      {item3.term}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>

                              <Col lg={6}>
                                <Form.Label className="fw-semibold m-2">
                                  Choose Day
                                </Form.Label>
                                <Form.Select
                                  onChange={formikk.handleChange}
                                  id="day"
                                  aria-label="Default select example"
                                  className="mb-3"
                                >
                                  <option>Choose Day</option>
                                  <option value="MONDAY">MONDAY</option>
                                  <option value="TUESDAY">TUESDAY</option>
                                  <option value="WEDNESDAY">WEDNESDAY</option>
                                  <option value="THURSDAY">THURSDAY</option>
                                  <option value="FRIDAY">FRIDAY</option>
                                </Form.Select>

                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="startTime"
                                  >
                                    <Form.Label className="fw-semibold mb-2">
                                      Start Time
                                    </Form.Label>

                                    <Form.Control
                                      as="input"
                                      type="time"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={formikk.values.startTime}
                                      isInvalid={!!formikk.errors.startTime}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formikk.errors.startTime}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="stopTime"
                                  >
                                    <Form.Label className="fw-semibold mb-2">
                                      Stop Time
                                    </Form.Label>

                                    <Form.Control
                                      as="input"
                                      type="time"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={formikk.values.stopTime}
                                      isInvalid={!!formikk.errors.stopTime}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formikk.errors.stopTime}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col className="mx-auto p-3">
                                  <div>
                                    <Button
                                      size="lg"
                                      variant="primary"
                                      className="fw-semibold"
                                      onClick={formikk.submitForm}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </Col>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      </Card.Body>
                    </Card>
                  )}
                </Formik>
                {/*Add Lesson Program End*/}

                {/*Lesson Program List Start*/}
                <Card
                  className="mt-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Row>
                    <Col>
                      <Card.Header className="fw-bold p-3" as="h5">
                        Lesson Program List
                      </Card.Header>

                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Lesson</th>
                            <th>Day</th>
                            <th>Start Time</th>
                            <th>Stop Time</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                          {lessonProgramPageList.map((item2) => (
                            <tr key={item2.id}>
                              <td>
                                <span>{item2.lessonName[0].lessonName}</span>
                              </td>

                              <td>
                                <span>{item2.day}</span>
                              </td>

                              <td>
                                <span>{item2.startTime}</span>
                              </td>

                              <td>
                                <span>{item2.stopTime}</span>
                              </td>

                              {/* <td>
                                <span>
                                  <Button
                                    variant="danger"
                                    onClick={() =>
                                      handleDelete2(item2.lessonProgramId)
                                    }
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </Button>
                                </span>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Row>
                        <Col className="d-flex justify-content-start ps-5">
                          Page {currentPage} of {totalPages}
                        </Col>
                        <Col>
                          <Pagination className="d-flex justify-content-end pe-3">
                            <Pagination.First onClick={showFirstPage} />
                            <Pagination.Prev onClick={showPrevPage} />

                            <Pagination.Item active>
                              {currentPage}
                            </Pagination.Item>

                            <Pagination.Next onClick={showNextPage} />
                            <Pagination.Last onClick={showLastPage} />
                          </Pagination>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Container>
              {/*Lesson Program List End*/}

              {/*Lesson Program Assignment Start*/}
              <Container fluid>
                <Formik
                  initialValues={{
                    lessonProgramId: [""],
                    teacherId: "",
                  }}
                  onSubmit={(values, actions) => {
                    axiosInstance
                      .post("/teachers/chooseLesson", values)
                      .then((response) => {
                        if (response.status === 200) {
                          const userInfo = response.data;
                          setMyState({
                            type: "LESSONPROGRAM",
                            item: userInfo,
                          });
                          toast.success(userInfo.message, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          actions.resetForm();
                          getLessonProgramUnassigned();
                        }
                      })
                      .catch((error) => {
                        {
                          !error.response.data.validations &&
                            toast.error(error.response.data.message, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                        }
                        {
                          error.response.data.validations &&
                            toast.error(
                              error.response.data.validations.lessonProgramId,
                              {
                                position: "top-center",
                                theme: "colored",
                                hideProgressBar: true,
                                autoClose: 1000,
                                closeOnClick: true,
                              }
                            );
                          toast.error(
                            error.response.data.validations.teacherId,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                        }
                      });
                    actions.setSubmitting(false);
                  }}
                >
                  {(formikk) => (
                    <Card
                      className="mt-5 ms-3 me-3 mb-5 text-center border border-3 shadow-sm bg-body rounded"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                        Lesson Program Assignment
                      </Card.Header>
                      <Card.Body>
                        <Table responsive striped bordered hover>
                          <thead>
                            <tr>
                              <th>Choose Lesson</th>
                              <th>Lesson</th>
                              <th>Day</th>
                              <th>Start Time</th>
                              <th>Stop Time</th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {list2.map((item2) => (
                              <tr key={item2.id}>
                                <td>
                                  <span>
                                    <Form.Check
                                      type="checkbox"
                                      id="lessonProgramId"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value={item2.lessonProgramId}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span>{item2.lessonName[0].lessonName}</span>
                                </td>
                                <td>
                                  <span>{item2.day}</span>
                                </td>

                                <td>
                                  <span>{item2.startTime}</span>
                                </td>

                                <td>
                                  <span>{item2.stopTime}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Row>
                          <Col>
                            <Form.Label className="fw-semibold mt-2">
                              Choose Teacher
                            </Form.Label>
                            <Form.Select
                              onChange={formikk.handleChange}
                              aria-label="Default select example"
                              id="teacherId"
                            >
                              <option>Choose Teacher</option>
                              {teacherList.map((item4) => (
                                <option
                                  className="fw-semibold m-2"
                                  value={item4.userId}
                                  key={item4.userId}
                                >
                                  {item4.name} {item4.surname}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col className=" mb-3 mt-4">
                            <Button
                              size="lg"
                              variant="primary"
                              className="fw-semibold"
                              onClick={() => {
                                formikk.submitForm();
                              }}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </Formik>
              </Container>
              {/*Lesson Program Assignment End*/}
            </Tab>
          </Tabs>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default LessonPage;
